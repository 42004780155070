import React from "react";
import "./aboutproduk.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import Fasilitasimages from "./Fasilitas";

const About = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6287789839789&text=Halo%20Romy,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Summarecon%20Bekasi%20(Apartemen)ttps://marketingperumahanbekasi.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const settings1 = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="about">
      <div className="containerabout">
        <div id="fasilitas" className="container-about">
          <h1>Experience a Home for Comfortable Living</h1>
          <div className="container-desk">
            <div className="desk">
              Summarecon Bekasi menawarkan berbagai jenis tipe properti salah
              satunya adalah apartemen. Summarecon Bekasi menawarkan 2 Apartemen
              dengan konsep yang berbeda. Springlake View merupakan salah satu
              produk apartemen yang diatawarkan oleh Summarecon Bekasi dengan
              konsep Lake Front Vertikal Living, yang mengutamakan kenyamanan
              hunian di tepi danau dengan gaya hidup modern yang praktis dan
              dinamis. Selain itu, The Primrose Condovilla apartemen ini
              memiliki konsep hunian yang mewah dalam bentuk kondominium dengan
              suasana yang lebih nyaman. Kedua aparetemen tersebut menawarkan
              berbagai macam fasilitas guna meningkatkan kenyamanan penghuninya,
              seperti:
            </div>
          </div>
          <div className="fasilitas-desk">
            <div className="fasilitas1">
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Direct Access to SMB
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Clubhouse
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Swimming Pool
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Children Playground
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;BBQ Area
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>&nbsp;&nbsp;Gym</span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Basketball Court
                </span>
              </div>
            </div>
            <div className="fasilitas2">
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Lakeside View
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Multifunction Room
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Prayer Room
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Security 24 Hours
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Laundry Room
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Food Store
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Jogging Track
                </span>
              </div>
            </div>
          </div>
          <div className="button">
            <div className="container-wa">
              <button
                className="buttonpenawaran"
                onClick={handleConsultationClick}>
                Learn More
              </button>
            </div>
          </div>
        </div>
        <div className="slider-fasilitas">
          <Slider {...settings1}>
            {Fasilitasimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default About;
