import Morizen1 from "../Morizen/Morizen (1).webp"
import Morizen2 from "../Morizen/Morizen (2).webp"
import Morizen3 from "../Morizen/Morizen (3).webp"
import Morizen4 from "../Morizen/Morizen (4).webp"
import Morizen5 from "../Morizen/Morizen (5).webp"
import Morizen6 from "../Morizen/Morizen (6).webp"
import Morizen7 from "../Morizen/Morizen (7).webp"
import Morizen8 from "../Morizen/Morizen (8).webp"
import Morizen9 from "../Morizen/Morizen (9).webp"
import Morizen10 from "../Morizen/Morizen (10).webp"
import Morizen11 from "../Morizen/Morizen (11).webp"
import Morizen12 from "../Morizen/Morizen (12).webp"
import Morizen13 from "../Morizen/Morizen (13).webp"

const Morizenimages =[
    Morizen1,Morizen2,Morizen3,Morizen4,Morizen5,Morizen6,Morizen7,Morizen8,Morizen9,Morizen10,Morizen11,Morizen12,Morizen13
    ]
    
    export default Morizenimages;
    
