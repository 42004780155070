import Springlake1 from "../Spring Lake/Spring Lake (1).webp"
import Springlake2 from "../Spring Lake/Spring Lake (2).webp"


const Springlakeimages =[
    Springlake1,Springlake2
    ]
    
    export default Springlakeimages;
    
