import React from "react";
import "./apartemen.scss";
import Apartemen1 from "./apartemen1/apartemen1.jsx";
import Fasilitas from "./AboutProduk/aboutproduk.jsx";

const apartemen = () => {
  return (
    <div id="apartement" className="apartment">
      <div className="container-header1"> Our Apartement</div>
      <div>
        <div className="Judul"></div>
        <Fasilitas />
        <Apartemen1 />
      </div>
    </div>
  );
};

export default apartemen;
