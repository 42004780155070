import Primrose1 from "../Primrose/Primrose (1).webp"
import Primrose2 from "../Primrose/Primrose (2).webp"
import Primrose3 from "../Primrose/Primrose (3).webp"
import Primrose4 from "../Primrose/Primrose (4).webp"

const Primroseimages =[
    Primrose1,Primrose2,Primrose3,Primrose4
    ]
    
    export default Primroseimages;
    
