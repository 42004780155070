import React from "react";
import "./produk.scss";
import data from "../../Component/Card/data.json";
import Card from "../../Component/Card/card";
import Magentaimages from "../../Media/Summarecon Bekasi/Magenta/Magenta";
import Mulberryimages from "../../Media/Summarecon Bekasi/Mulberry/Mulberry";
import Morizenimages from "../../Media/Summarecon Bekasi/Morizen/Morizen";
import Oliveimages from "../../Media/Summarecon Bekasi/Olive/Olive";
import Burgundyimages from "../../Media/Summarecon Bekasi/Burgundy/Burgundy";
import Violaimages from "../../Media/Summarecon Bekasi/Viola/Viola";
import Jasmiaimages from "../../Media/Summarecon Bekasi/Jasmia/Jasmia";

const imageMap = {
  Magentaimages,
  Burgundyimages,
  Mulberryimages,
  Morizenimages,
  Oliveimages,
  Violaimages,
  Jasmiaimages,

  // Add other image arrays here if you have more sets
};

const produk = () => {
  return (
    <div id="rumah" className="produk">
      <h1>Our Residentials</h1>

      <div className="cardhome-containerf">
        {data.cards.map((card, index) => {
          // Determine the logo dynamically
          // Determine the logo dynamically
          return (
            <Card
              key={index}
              carousel={imageMap[card.imagesKey]}
              title={card.title}
              subtitle={card.subtitle}
              price={card.price}
              subprice={card.subprice}
              details={card.details}
              whatsappLink={card.whatsappLink} // Pass the WhatsApp link
            />
          );
        })}
      </div>
    </div>
  );
};

export default produk;
