import Viola1 from "../Viola/Viola (1).webp"
import Viola2 from "../Viola/Viola (2).webp"
import Viola3 from "../Viola/Viola (3).webp"
import Viola4 from "../Viola/Viola (4).webp"
import Viola5 from "../Viola/Viola (5).webp"
import Viola6 from "../Viola/Viola (6).webp"
import Viola7 from "../Viola/Viola (7).webp"


const Violaimages =[
    Viola1,Viola2,Viola3,Viola4,Viola5,Viola6,Viola7
    ]
    
    export default Violaimages;
    
