import React from "react";
import "./about.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import aboutimg from "../../Media/Summarecon Bekasi/Spring Lake/Spring Lake (1).webp";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";

const About = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6287789839789&text=Halo%20Romy,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Summarecon%20Bekasi%20(About)%20https://marketingperumahanbekasi.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="about">
      <div id="about" className="container-about">
        <h1>A City Filled with Aspiration and Pride</h1>
        <div className="container-desk">
          <div className="desk">
            Summarecon Bekasi adalah sebuah kawasan modern yang dikembangkan
            oleh PT Summarecon Agung Tbk, terletak di Bekasi Utara, Jawa Barat.
            Kawasan ini mulai dikembangkan pada Maret 2010 dan mencakup area
            seluas 400 hektar. Tujuan dari pengembangan ini adalah untuk
            menciptakan pusat komersial dan hunian yang terintegrasi, memberikan
            berbagai fasilitas modern bagi penghuninya
          </div>
        </div>
        <div className="container-benefit">
          <div className="benefit-list">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;Environmental Friendly City
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;Green Living
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;Vibrant Valley
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;Smart Living Ecosystem
              </span>
            </div>
          </div>
        </div>
        <div className="button">
          <div className="container-wa">
            <button
              className="buttonpenawaran"
              onClick={handleConsultationClick}>
              Learn More
            </button>
          </div>
        </div>
      </div>
      <div className="gambarabout">
        <img src={aboutimg} alt={aboutimg} />
      </div>
    </div>
  );
};

export default About;
