import Magenta1 from "../Magenta/Magenta (1).webp"
import Magenta2 from "../Magenta/Magenta (2).webp"
import Magenta3 from "../Magenta/Magenta (3).webp"
import Magenta4 from "../Magenta/Magenta (4).webp"
import Magenta5 from "../Magenta/Magenta (5).webp"
import Magenta6 from "../Magenta/Magenta (6).webp"
import Magenta7 from "../Magenta/Magenta (7).webp"
import Magenta8 from "../Magenta/Magenta (8).webp"

const Magentaimages =[
    Magenta1,Magenta2,Magenta3,Magenta4,Magenta5,Magenta6,Magenta7,Magenta8
    ]
    
    export default Magentaimages;
    
