import Fasilitas1 from "../../../Media/Fasilitas/Fasilitas (1).webp"
import Fasilitas2 from "../../../Media/Fasilitas/Fasilitas (2).webp"
import Fasilitas3 from "../../../Media/Fasilitas/Fasilitas (3).webp"
import Fasilitas4 from "../../../Media/Fasilitas/Fasilitas (4).webp"
import Fasilitas5 from "../../../Media/Fasilitas/Fasilitas (5).webp"
import Fasilitas6 from "../../../Media/Fasilitas/Fasilitas (6).webp"
import Fasilitas7 from "../../../Media/Fasilitas/Fasilitas (7).webp"
import Fasilitas8 from "../../../Media/Fasilitas/Fasilitas (8).webp"


const Fasilitasimages =[
    
    Fasilitas1, Fasilitas2,Fasilitas3,Fasilitas4,Fasilitas5,Fasilitas6,Fasilitas7,Fasilitas8
    ]
    
    export default Fasilitasimages;