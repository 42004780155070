import Olive1 from "../Olive/Olive.webp"
import Olive2 from "../Olive/Olive (1).webp"
import Olive3 from "../Olive/Olive (2).webp"
import Olive4 from "../Olive/Olive (3).webp"

const Oliveimages =[
    Olive1,Olive2,Olive3,Olive4
    ]
    
    export default Oliveimages;
    
