
import Burgundy2 from "../Burgundy/Burgundy (1).webp"
import Burgundy3 from "../Burgundy/Burgundy (2).webp"
import Burgundy4 from "../Burgundy/Burgundy (3).webp"

const Burgundyimages =[
    ,Burgundy2,Burgundy3,Burgundy4
    ]
    
    export default Burgundyimages;
    
