import React from "react";
import "./promo.scss";
import penawaran from "../../Media/Hardsell_Sumbek.webp";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";

const Penawaran = () => {
  const Cherrywa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6287789839789&text=Halo%20Romy,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Summarecon%20Bekasi%20(Promo)%20https://marketingperumahanbekasi.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  return (
    <div id="promo" className="promosumbog">
      <div className="promo-sumbog">
        <div className="gambarpromo">
          <img src={penawaran} alt={penawaran} />
        </div>
        <div className="ket-promo">
          <div className="judul">
            <h1>Promo Summarecon Bekasi</h1>
          </div>
          <div className="promolist">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "black"}}>&nbsp;&nbsp;Siap Huni</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "black"}}>
                &nbsp;&nbsp;Discount up to 10%
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "black"}}>&nbsp;&nbsp;Free DP</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "black"}}>&nbsp;&nbsp;Free PPN</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "black"}}>&nbsp;&nbsp;Free Biaya BPHTB</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "black"}}>&nbsp;&nbsp;Free KPA</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "black"}}>&nbsp;&nbsp;Free Canopy</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "black"}}>&nbsp;&nbsp;Free AC</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "black"}}>&nbsp;&nbsp;Full Furnished</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "black"}}>&nbsp;&nbsp;Free IPL</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "black"}}>
                &nbsp;&nbsp;Full Smarthome System
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "black"}}>
                &nbsp;&nbsp;Free Smartdoor Lock
              </span>
            </div>
          </div>
          <button className="buttonpenawaran" onClick={Cherrywa}>
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default Penawaran;
